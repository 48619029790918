<template>
    <div id="home">
		<section id="introduce-section" class="pb-5">
			<div class="container">
				<div class="row jusitfy-content-betwwen g-5">
					<div class="col-lg-5">
						<h1 class="fw-bold mb-4">Current Average <br> Winning % is</h1>
						<div class="average mb-4">{{currentAverage}}</div>
						<router-link :to="{ name: 'Plan' }" class="btn btn-main-1 text-white d-block btn-lg px-5 w-100">7 Days Free Trial</router-link>
					</div>
					<div class="col-lg-7">
						<h2 class="fs-6 text-center mb-0">Winning % Evolution</h2>
						<div style="height: 270px">
							<canvas id="myChart"></canvas>
						</div>						
						<div class="d-flex justify-content-between mt-2" style="font-size:0.8rem">
							<span>{{xFirstLabel}}</span>
							<span>{{xLastLabel}}</span>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="container py-5">
			<div class="row g-5">
				<div class="col-lg-6">
					<h2 class="fs-1 fw-bolder d-flex align-items-center px-5 py-4 mb-0 ratio ratio-16x9 bg-main-3 h-100 rounded">How BinaryFlash Serves Binary Option Traders</h2>
				</div>
				<div class="col-lg-6">
					<div class="ratio ratio-16x9">
						<iframe src="https://www.youtube.com/embed/Y9HtE-WWUBs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
					</div>
				</div>
			</div>
		</section>

		<section id="why-section" class="pt-5">
			<div class="container">
				<div class="row">
					<div class="col-lg-6"> 
						<h2 class="title fw-bolder mb-5">Why <br> BinaryFlash</h2>
						<img class="pic img-fluid" src="@/assets/img/home-page/Img_01.png" alt="">
					</div>
					<div class="col-lg-6">
						<ul class="mb-0 ps-2">
							<li class="card p-3 bg-main-3 mb-5">
								<h3 class="card-number fw-bolder">1</h3>
								<div class="card-body">
									<h3 class="card-title fw-bold d-flex align-items-center"> 
										<img class="card-title-icon img-fluid me-3" src="@/assets/img/home-page/why-icon-lightning.svg" alt="">
										Advanced Technology
									</h3>
									<p class="card-text">Our proprietary signal generation engine was developed by quants with advanced knowledge and extensive experience in mathematical finance, AI, and algorithmic trading.</p>
								</div>
							</li>

							<li class="card p-3 bg-main-3 mb-5">
								<h3 class="card-number fw-bolder">2</h3>
								<div class="card-body">
									<h3 class="card-title fw-bold d-flex align-items-center"> 
										<img class="card-title-icon img-fluid me-3" src="@/assets/img/home-page/why-icon-up.svg" alt="">
										High-Quality Signals
									</h3>
									<p class="card-text">Our signals contain not only the trade direction and expiry time setting, but also the risk-management built-in, optimum position size. Machine learning is applied to ensure the robustness of the signals in view of market condition change.</p>
								</div>
							</li>

							<li class="card p-3 bg-main-3">
								<h3 class="card-number fw-bolder">3</h3>
								<div class="card-body">
									<h3 class="card-title fw-bold d-flex align-items-center"> 
										<img class="card-title-icon img-fluid me-3" src="@/assets/img/home-page/why-icon-unlock.svg" alt="">
										Transparency
									</h3>
									<p class="card-text">Our signal quality is totally transparent. Once the corresponding trade of a signal is concluded, the overall winning percentage will be automatically updated and posted.</p>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</section>

		<section id="join-section">
			<div class="container text-center">
				<h2 class="fw-bold fs-1 mb-5">Enjoy a 7-day free trial</h2>
				<p class="content mb-5">before joining the membership, which can be cancelled anytime during the subscription period.</p>
				<router-link :to="{ name: 'Plan' }" class="btn btn-lg btn-main-1 text-white px-5">7 Days Free Trial</router-link>
				
			</div>
		</section>
      
    </div>
</template>

<script>
import Chart from 'chart.js';
export default {
    name: 'HomePage',
    data(){
        return{
           currentAverage: 0,
		   xFirstLabel:"",
		   xLastLabel:""
        }
    },
	methods:{
		getChart(){
			this.axios.get('https://binaryflash.codewcy.com/api/site/chart').then(response => {
				let chartData = response.data.result.chart;
				let lastIndex = chartData.length - 1;
				let maxIndex = -1;
				let maxData = 0;	
				
				let xLabels = [];
				let yData = [];

				for(let i = 0 ; i < chartData.length ; i++){
					let winning_percentage = chartData[i].winning_percentage;
					let report_time = chartData[i].report_time;
					let average_winning_percentage = chartData[i].average_winning_percentage;		
		
					xLabels.push(report_time);
					yData.push(winning_percentage);
					this.currentAverage = average_winning_percentage;

					if(winning_percentage > maxData){
						maxData = winning_percentage;
						maxIndex = i;
					}
				}

				this.xFirstLabel = chartData[0].report_time;
				this.xLastLabel = chartData[lastIndex].report_time;

				// 圖表文字顏色
				Chart.defaults.global.defaultFontColor = "#ffffff";
			
				// 漸層底色
				let myChartEl = document.getElementById('myChart');
				let context  = document.getElementById('myChart').getContext('2d'),
				gradient = context.createLinearGradient(0, 0, 0, 250);
				gradient.addColorStop(0, 'rgba(81, 213, 255, 1)');
				gradient.addColorStop(0.5, 'rgba(81, 213, 255, 0.7)');
				gradient.addColorStop(0.8, 'rgba(81, 213, 255, 0.5)');
				gradient.addColorStop(1, 'rgba(81, 213, 255, 0.2)');

				const data = {
					labels: xLabels,
					datasets: [{
						label: 'Winning % Evolution',
						backgroundColor: gradient,
						borderColor: 'rgb(81, 213, 255)',
						pointBorderColor:function(context){
							// let value = context.labels[index];
							let index = context.dataIndex;
							if(index === lastIndex){
								return '#ffffff'
							}
						},
						pointBorderWidth:function(context){
							let index = context.dataIndex;
							if(index === lastIndex){
								return 3
								
							}else if(index === maxIndex){
								return 2
							}
						},
						pointRadius: function(context) {
							let index = context.dataIndex;
							if(index === lastIndex){
								return 8

							}else if(index === maxIndex){
								return 6

							}else{
								return 3
							}
						},
						pointBackgroundColor: function(context) {
							let index = context.dataIndex;

							if(index === lastIndex){
								return '#FFE600'

							}else if(index === maxIndex){
								return '#FFFFFF'
							}
						},
						data: yData,
						tension:0,
					}],
				};

				let originalLineDraw = Chart.controllers.line.prototype.draw;
				Chart.helpers.extend(Chart.controllers.line.prototype, {
					draw: function() {
						originalLineDraw.apply(this, arguments);

						let chart = this.chart;
					
						// 最新一筆特殊標示
						{
							// 垂直線
							let xAxis = chart.scales['x-axis-0'];
							let yAxis = chart.scales['y-axis-0'];
							let yTop = yAxis.getPixelForValue(yData[lastIndex]);
							let yBottom = yAxis.bottom;
							let xPoint = xAxis.getPixelForValue(undefined, lastIndex);

							context.save();
							context.beginPath();
							context.setLineDash([6]);
							context.moveTo(xPoint, yTop + 10);
							context.strokeStyle = '#F1FF51';
							context.lineWidth = 3;
							context.lineTo(xPoint, yBottom);
							context.stroke();
							context.restore();

							// 畫圓角
							CanvasRenderingContext2D.prototype.roundRect = function (x, y, width, height, radius) {
								if (width < 2 * radius) radius = width / 2;
								if (height < 2 * radius) radius = height / 2;
								this.beginPath();
								this.moveTo(x + radius, y);
								this.arcTo(x + width, y, x + width, y + height, radius);
								this.arcTo(x + width, y + height, x, y + height, radius);
								this.arcTo(x, y + height, x, y, radius);
								this.arcTo(x, y, x + width, y, radius);
								this.closePath();
								return this;
							}

							let item = data.datasets[0];
							let text = item.data[lastIndex] + '%';
							let font = 'bolder 18pt SF Pro Display';
							context.save();
							context.font = font;
							context.textBaseline = 'top';
							context.textAlign = 'center';
							
							var width = context.measureText(text).width + 20;
							context.fillStyle = '#F1FF51';
							context.roundRect(xPoint - (width / 2),  yTop - 50, width, 35, 15);	
							context.fill();
							
							context.fillStyle = '#000000';
							context.fillText(text, xPoint, yTop - 45);
							context.restore();
							
						}

						// 最高一筆特殊標示
						if(lastIndex !== maxIndex){
							// 垂直線
							let xAxis = chart.scales['x-axis-0'];
							let yAxis = chart.scales['y-axis-0'];
							let yTop = yAxis.getPixelForValue(yData[maxIndex]);
							let yBottom = yAxis.bottom;
							let xPoint = xAxis.getPixelForValue(undefined, maxIndex);

							context.save();
							context.beginPath();
							context.setLineDash([6]);
							context.moveTo(xPoint, yTop + 10);
							context.strokeStyle = '#51D5FF';
							context.lineWidth = 3;
							context.lineTo(xPoint, yBottom);
							context.stroke();
							context.restore();

							let item = data.datasets[0];
							let text = item.data[maxIndex] + '%';
							let font = 'bolder 14pt SF Pro Display';
							context.save();
							context.font = font;
							context.textBaseline = 'top';
							context.textAlign = 'center';
							
							var width = context.measureText(text).width + 20;							
							context.fillStyle = '#51D5FF';
							context.fillText(text, xPoint, yTop - 30);
							context.restore();
							
						}
					}
				});
				
		
				new Chart(
					myChartEl,
					{
						type: 'line',
						data,
						
						options: {
							maintainAspectRatio: false,
							layout: {
								padding: {
									top:20,
									right: 40
								}
							},
							scales: {
								xAxes: [{
									display: false,
								}],
								yAxes: [{
									gridLines: {
										color: "rgba(0, 0, 0, 0)",
									},
									ticks: {
										fontSize: 16,
										min: 0,
										max: 100,
										callback: function (value) {
											return (value).toFixed(0) + '%'; 
										},
        								stepSize: 25,

									} 
								}],
								
							},
							legend: {
								display: false
							},
							tooltips: {
								titleFontSize:14,
								titleMarginBottom:8,
								bodyFontSize:14,
								xPadding:10,
								yPadding:10,
								displayColors:false,
								intersect: false,
								callbacks: {
									labelTextColor(tooltipItem, chart) {
										return 'lightgray';
									}
								}
							},
							
						},
						
					}
					
				);

			})
		}
	},
    mounted() {
		this.getChart();
	}
}
</script>


<style lang="scss" scoped>
	@import "@/scss/all.scss";

	#home{
		@include media-breakpoint-up(lg) { 
			margin-bottom: -6rem;
			
		}

		@include media-breakpoint-down(lg) { 
			margin-bottom: -3rem;
			
		}
	}

	#introduce-section{

		.average{
			position: relative;
			display: inline-block;
			font-size: 5rem;
			font-weight: 800;
			color: $color-highlight;
			padding: 0 12px;

			&:after{
				content: "";
				background-color: $main-3;
				width: 100%;
				height: 50%;
				position: absolute;
				bottom: 0;
				left: 0;
				z-index: -1;
			}
		}
	}

	#why-section{
		position: relative;

		@include media-breakpoint-up(lg) { 
			padding-bottom: 8rem;
		}

		@include media-breakpoint-down(lg) { 
			padding-bottom: 5rem;
		}

		.title{
			@include media-breakpoint-up(lg) { 
				font-size: 4rem;
			}

			@include media-breakpoint-down(lg) { 
				font-size: $h1-font-size;
				height: 20rem;
			}
		}

		.pic{
			
			@include media-breakpoint-up(lg) { 
				width: 90%;
			}

			@include media-breakpoint-down(lg) { 
				position: absolute;
				z-index: -1;
				width: 55%;
				min-width: 18rem;
				right: -5%;
				top: 0rem;
			}
			
		}

		.card{
			position: relative;
		}

		.card-number{
			position: absolute;
			text-align: center;
			color: $color-highlight;

			@include media-breakpoint-up(lg) { 
				font-size: 4rem;
				left: -2rem;
				top: -2.2rem;
				width: 4rem;
			}

			@include media-breakpoint-down(lg) { 
				font-size: $h1-font-size;
				left: -2rem;
				top: -1.3rem;
				width: 4rem;
			}
		}

		.card-title-icon{
			height: 2rem;
		}
	}

	#join-section{
		position: relative;
		padding: 5rem 0;
		

		background-image: 
		 	url(~@/assets/img/home-page/Img_Glow_01.png),
			url(~@/assets/img/home-page/Img_Glow_02.png),
			url(~@/assets/img/home-page/Img_Glow_03.png);

		background-repeat: 
			no-repeat,
			no-repeat,
			no-repeat;

		background-position: 
			top left,
			bottom right,
			center;

		@include media-breakpoint-up(lg) { 
			background-size: 
				20%,
				20%,
				100%;
		}

		@include media-breakpoint-down(lg) { 
			background-size: 
				40%,
				40%,
				150%;
		}
		
		&::before{
			content: "";
			background: $gradient-1;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;

			width: 100%;
			height: 100%;
			z-index: -1;
		}

		.content{
			max-width: 30rem;
			margin: auto;
		}
	}
	

</style>
